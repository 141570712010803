import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MdUnarchive } from "react-icons/md";
import {
  ContainerContent,
  Content,
  MainContainer,
  BoldLabel,
  StyledCard,
  BoldLabelTitle,
  ProductListContainer,
  ProductListItem,
} from "./styles";
import { DefaultPalettColors } from "../../assets/colors";
import { Header } from "../../components/Header";
import { Card } from "react-bootstrap";
import ReactLoading from "react-loading";
import { api } from "../../services/api";
import { AuthContext } from "../../Shared/AuthContext";
import { ToastMessage } from "../../components/Toast";
import { ModalMessage } from "../../components/ModalMessage";

import {
  IModalMessage,
  IModalMessageType,
  INote,
  IProduct,
  IToastType,
} from "../../Shared/Interfaces";
import { getLocalDate, getLocalTime } from "../../utils/formatValue";
import SelectedProduct from "./SelectedProduct";
import NotesHistory from "./NotesHistory";
import useIsMobile from "../../hooks/screen";

export const Products = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingMakeNote, setLoadingMakeNote] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [notes, setNotes] = useState<INote[]>([]);
  const [isValidDUM, setIsValidDUM] = useState(true);
  const [noteType, setNoteType] = useState("pallet");
  const [quantity, setQuantity] = useState(0);
  const [currentLoadingStatusLabel, setCurrentLoadingStatusLabel] =
    useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessageType, setToastMessageType] = useState<IToastType>(
    IToastType.unknow
  );
  const [toastMessage, setToastMessage] = useState("");
  const { authenticateUser } = useContext(AuthContext);
  const location = useLocation();
  const noteTotal = notes.reduce((a, { QTDEPROD }) => a + QTDEPROD, 0);

  const isMobile = useIsMobile();

  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    console.log("isMobile:", isMobile);
  }, [isMobile]);

  const [axiosConfig, setAxiosConfig] = useState({
    headers: {
      Authorization: `Bearer ${localStorage.getItem("apontamento.jwt")}`,
    },
  });

  const [modalMessage, setModalMessage] = useState<IModalMessage>({
    show: false,
    text: "",
    type: IModalMessageType.unknow,
  });

  const refreshData = async () => {
    if (selectedProduct) {
      const { CODPROD, IDIPROC } = selectedProduct;
      await getData();
      const searchedProduct = products.find(
        (item) => item.CODPROD === CODPROD && item.IDIPROC === IDIPROC
      );
      if (searchedProduct) setSelectedProduct({ ...searchedProduct });
    }
  };

  const resetAfterSuccess = async () => {
    setToastMessageType(IToastType.success);
    setShowToast(true);
    setToastMessage("Apontamento realizado com sucesso!");
    setQuantity(0);
    setNoteType("pallet");
    await refreshData();
  };
  const resetAfterError = async () => {
    setLoadingMakeNote(false);
    setLoading(false);
    setQuantity(0);
    setNoteType("pallet");
    await refreshData();
  };

  useEffect(() => {
    const toggleProduct = async () => {
      if (selectedProduct) {
        if (selectedProduct?.IDIATV && !selectedProduct?.STATUSAPO) {
          try {
            await startNote(selectedProduct.IDIATV).catch((error) =>
              console.log(error)
            );
          } catch (error) {
            console.log(error);
          }
        }
        await getNotesHystory();
      }
    };
    toggleProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const getData = async () => {
    setLoading(true);
    const response = await api.get(
      `productionOrder?CODEMP=${authenticateUser?.CODEMP}`,
      axiosConfig
    );
    setLoading(false);
    if (response?.data) {
      setProducts(response?.data);
    }
  };
  useEffect(() => {
    const { jwt } =
      (location?.state as any) || localStorage.getItem("apontamento.jwt");
    setAxiosConfig({ headers: { Authorization: `Bearer ${jwt}` } });
    getData();
  }, []);

  const handleSubmit = async () => {
    try {
      if (selectedProduct?.QTDPALLET && !(quantity > 0)) {
        throw new Error("É obrigatório informar uma quantidade.");
      }

      if (
        selectedProduct?.QTDPALLET &&
        quantity >= selectedProduct?.QTDPALLET
      ) {
        throw new Error(
          "A quantidade informada não pode ser igual ou superior a quantidade do pallet."
        );
      }

      setLoadingMakeNote(true);
      await postNote(quantity);
      await getNotesHystory();
      setLoadingMakeNote(false);
      resetAfterSuccess();
    } catch (error) {
      resetAfterError();

      if (error instanceof Error) {
        setModalMessage({
          show: true,
          type: IModalMessageType.error,
          text: error.message,
        });
      }
      setLoadingMakeNote(false); //'secondary' | 'success' | 'danger' | 'warning' | 'info'
    }
  };

  const handleSearchDUM = async (e: any) => {
    try {
      if (e.keyCode === 13) {
        if (
          selectedProduct?.QTDPALLET &&
          noteTotal + selectedProduct?.QTDPALLET > selectedProduct?.QTDPRODUZIR
        ) {
          throw new Error(
            "A quantidade de apontamentos não pode ser maior que a quantidade da ordem de produção!"
          );
        }

        setLoadingMakeNote(true);
        if (selectedProduct?.DUM === e.target.value) {
          if (selectedProduct?.QTDPALLET) {
            try {
              setQuantity(selectedProduct.QTDPALLET);
              await postNote(selectedProduct.QTDPALLET);
              await getNotesHystory();
              setLoadingMakeNote(false);
              resetAfterSuccess();
            } catch (error) {
              if (error instanceof Error) throw new Error(error.message);
            }
          }

          setIsValidDUM(true);
        } else {
          setIsValidDUM(false);
        }
        setLoadingMakeNote(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        resetAfterError();
        setModalMessage({
          show: true,
          type: IModalMessageType.error,
          text: error.message,
        });
      }
    }
  };

  const truncateText = (text: string) => {
    if (text.length <= 29) {
      return text;
    }
    const firstPart = text.slice(0, 24);
    const lastPart = "..." + text.slice(-5).toString().trim();

    return firstPart + (lastPart.toString().trim() === "..." ? "" : lastPart);
  };

  const startNote = async (IDIATV: Number) => {
    try {
      setCurrentLoadingStatusLabel("Iniciando apontamento.");

      if (selectedProduct) {
        const data = {
          IDIATV,
        };
        await api.post("startNote", data, axiosConfig).catch((error) => {
          console.log(error);
        });
      }
    } catch (error) {}
  };

  const confirmNote = async (NUAPO: number, QUANTITY: Number) => {
    try {
      setCurrentLoadingStatusLabel("Confirmando apontamento.");

      const data = {
        NUAPO,
        IDIATV: selectedProduct?.IDIATV,
        ACEITARQTDMAIOR: true,
        ULTIMOAPONTAMENTO: false,
        RESPOSTA_ULTIMO_APONTAMENTO: false,
        RESPOSTA_SERIE_LIBERADO: false,
        RESPOSTA_SERIE_LIBERADO_PERDA: false,
        ACEITA_PROPORCAO_INVALIDA_MPALTERNATIVA: true,
        IDIPROC: selectedProduct?.IDIPROC,
        QTDPRODUZIR: QUANTITY,
        CODPROD: selectedProduct?.CODPROD,
      };

      const response = await api.post("confirmNote", data, axiosConfig);

      //      console.log('response-confirm', response, response?.data?.statusMessage)

      if (response?.data?.statusMessage) {
        throw new Error(response?.data?.statusMessage);
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(` Confirmação de apontamento: ${error.message} `);
      }
    }
  };

  const postNote = async (QUANTITY: number) => {
    // console.log("-->", selectedProduct);
    if (selectedProduct) {
      try {
        if (selectedProduct?.STATUSAPO === "P" && selectedProduct?.NUAPO) {
          setCurrentLoadingStatusLabel("Confirmando apontamentos pendentes.");
          await confirmNote(Number(selectedProduct.NUAPO), QUANTITY);
        }

        setCurrentLoadingStatusLabel("Enviando apontamento.");

        const { IDIATV, CODPROD } = selectedProduct;
        const data = { IDIATV, CODPROD, QTDPRODUZIR: QUANTITY };

        const response = await api.post("makenote", data, axiosConfig); //LISTAPENDENTES

        // console.log("response@@##", response);

        setLoading(false);
      } catch (error) {
        setLoading(false);

        const errorAux = { ...(error as any) };
        const message = errorAux?.response?.data?.message || "";

        if (error instanceof Error) {
          throw new Error(message + "  " + error?.message);
        }
      }
    }
  };

  const getNotesHystory = async () => {
    if (selectedProduct) {
      const { IDIPROC, CODPROD } = selectedProduct;
      const response = await api.get(
        `findProductNoteHistory?IDIPROC=${IDIPROC}&CODPROD=${CODPROD}`,
        axiosConfig
      );
      setNotes(response.data);
    }
  };

  return (
    <MainContainer>
      <ContainerContent>
        <Content>
          <ToastMessage
            setShowToast={setShowToast}
            showToast={showToast}
            toastMessage={toastMessage}
            toastMessageType={toastMessageType}
          />
          <ModalMessage
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />

          <Header
            icon={
              <MdUnarchive
                color={DefaultPalettColors.invoice.orange}
                size={24}
              />
            }
            label={"Apontamento Produção"}
          />
          <div
            style={{
              flex: "10",
              overflowY: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ProductListContainer show={!isMobile || !selectedProduct}>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <ReactLoading
                    type={"cylon"}
                    color={"#ff8000"}
                    height={"150px"}
                    width={"150px"}
                  />
                </div>
              )}

              {products.length > 0 &&
                products.map((product, idx) => (
                  <ProductListItem key={idx}>
                    <StyledCard
                      key={idx}
                      text={"dark"}
                      bg={"Light"}
                      className="mb-2"
                      onClick={() => setSelectedProduct(product)}
                    >
                      <Card.Header
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "80px",
                        }}
                      >
                        <img
                          src={
                            product?.IMAGEM
                              ? `data:imagem/png;base64,${product.IMAGEM}`
                              : "/noimage.jpeg"
                          }
                          alt="imagem"
                          style={{ width: "40px", height: "auto" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "5px",
                          }}
                        >
                          <BoldLabel
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "18px",
                            }}
                          >
                            {product.CODPROD}
                          </BoldLabel>
                          <BoldLabelTitle
                            style={{ fontSize: isMobile ? "10px" : "12px" }}
                          >
                            {truncateText(product.DESCRPROD)}
                          </BoldLabelTitle>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          <BoldLabel>Data:</BoldLabel>
                          {getLocalDate(product.DHINC.toString())}{" "}
                          {getLocalTime(product.DHINC.toString())}
                        </div>

                        <div>
                          <BoldLabel>Ordem P.:</BoldLabel>
                          {product.IDIPROC}
                        </div>
                        <div>
                          <BoldLabel>Saldo:</BoldLabel> {product?.SALDO}
                        </div>
                      </Card.Body>
                    </StyledCard>
                  </ProductListItem>
                ))}
            </ProductListContainer>

            {(loading || loadingMakeNote) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <h2 style={{ color: "white" }}>
                    {currentLoadingStatusLabel}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <ReactLoading
                    type={"cylon"}
                    color={"#ff8000"}
                    height={"150px"}
                    width={"150px"}
                  />
                </div>
              </div>
            )}
            {selectedProduct && !loadingMakeNote && !loading && (
              <SelectedProduct
                selectedProduct={selectedProduct}
                noteType={noteType}
                setNoteType={setNoteType}
                setQuantity={setQuantity}
                handleSearchDUM={handleSearchDUM}
                handleSubmit={handleSubmit}
                isValidDUM={isValidDUM}
                noteTotal={noteTotal.toString()}
                setSelectedProduct={setSelectedProduct}
                isMobile={isMobile}
              >
                <NotesHistory notes={notes} />
              </SelectedProduct>
            )}
          </div>
        </Content>
      </ContainerContent>
    </MainContainer>
  );
};
